import { Button, Input, Modal } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React, { Component } from 'react';
import styles from './index.less';
import type { SecuritySettingsInterface } from './interface';
import Store from './model';

const reg = /[^0-9]/ig;
@observer
class SecurityCenterModal extends Component<{ store: Store; }> {
  render() {
    const { isNewMobile, verificationCodeClick, oauthInfo, getModalWXVisibleParams, number, getVerificationCode, getModalPhoneVisibleParams, getSecuritySettings, getBinding, getModalPassWordParams, newPassWord, confirmNewPassWord, setValue, originalPassWord, isPassword, verificationCode, originalMobile, newMobile } = this.props?.store;

    return (
      <>
        <Modal {...getModalPassWordParams}>
          <div>
            {isPassword ? (
              <div className={styles.item}>
                <span className={styles.label}>
                  原密码：
                </span>
                <Input.Password
                  className={styles.itemInput}
                  maxLength={12}
                  onChange={(e) => {
                    setValue('originalPassWord', e.target.value);
                  }}
                  placeholder="请输入"
                  value={originalPassWord}
                />
              </div>
            ) : undefined}
            <div className={styles.item}>
              <span className={styles.label}>
                新密码：
              </span>
              <Input.Password
                className={styles.itemInput}
                maxLength={12}
                onChange={(e) => {
                  setValue('newPassWord', e.target.value);
                }}
                placeholder="请输入"
                value={newPassWord}
              />
            </div>
            <div className={styles.item}>
              <span className={styles.label}>
                确认新密码：
              </span>
              <Input.Password
                className={styles.itemInput}
                maxLength={12}
                onChange={(e) => {
                  setValue('confirmNewPassWord', e.target.value);
                }}
                placeholder="请输入"
                value={confirmNewPassWord}
              />
            </div>
          </div>
        </Modal>
        <Modal {...getModalPhoneVisibleParams}>
          <div>
            {isNewMobile ? (
              <div className={styles.item}>
                <span className={styles.label}>
                  新手机号：
                </span>
                <Input
                  className={styles.itemInput}
                  maxLength={11}
                  onChange={(e) => {
                    setValue('newMobile', e.target.value.replace(reg, ''));
                  }}
                  placeholder="请输入"
                  value={newMobile}
                />
              </div>
            ) : (
              <div className={styles.item}>
                <span className={styles.label}>
                  原手机号：
                </span>
                <span className={styles.itemInput}>
                  {originalMobile || '-'}
                </span>
              </div>
            )}
            <div className={styles.item}>
              <span className={styles.label}>
                验证码：
              </span>
              <div className={`${styles.itemInput} ${styles.verificationCode}`}>
                <Input
                  className={styles.verificationCodeInput}
                  maxLength={8}
                  onChange={(e) => {
                    setValue('verificationCode', e.target.value.replace(reg, ''));
                  }}
                  placeholder="请输入"
                  value={verificationCode}
                />
                <Button
                  className={styles.getVerificationCode}
                  disabled={getVerificationCode}
                  onClick={verificationCodeClick}
                >
                  {getVerificationCode ? `${number}s后重新获取` : '获取验证码'}
                </Button>
                <div/>
              </div>

            </div>

          </div>
        </Modal>
        <Modal {...getModalWXVisibleParams}>
          <div className={styles.wx}>
            您即将解绑
            <span>
              微信
            </span>
            账号
            <span>
              {oauthInfo?.nickname || '-'}
            </span>
            ，解绑之后您仍旧可以使用其他关联账号来登录您之前的账号
          </div>
        </Modal>
      </>
    );
  }
}

@observer
export default class SecurityCenter extends Component {
  constructor(prop) {
    super(prop);
    this.store = new Store();
  }

  private readonly store: Store;

  private renderItem(data: SecuritySettingsInterface[], title: string, binding?: boolean): React.ReactNode {
    const dataNode = data?.map((item) => {
      return (
        <div key={nanoid()}>
          <div className={`${styles.leftBlock} ${binding ? styles.binding : undefined}`}>
            <img src={item.icon}/>
            <div>
              <span>
                {item.title}
              </span>
              {item.prompt ? (
                <span className={styles.prompt}>
                  {item.prompt}
                </span>
              ) : undefined}
            </div>
          </div>
          <Button
            className={styles.buttonTex}
            onClick={item.onClick}
          >
            {item.buttonTxt}
          </Button>
        </div>
      );
    }) || [];
    return (
      <div className={styles.content}>
        <span>
          {title}
        </span>
        <div className={styles.feature}>
          {dataNode}
        </div>
      </div>
    );
  }

  render() {
    const { getSecuritySettings, getBinding } = this.store;
    return (
      <div className={styles.page}>
        <div>
          {this.renderItem(getSecuritySettings(), '安全设置')}
          {this.renderItem(getBinding, '账号绑定', true)}
        </div>
        <SecurityCenterModal store={this.store}/>
      </div>
    );
  }
}
